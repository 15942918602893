"use strict";

import { FC, ReactElement, useContext, useEffect, useMemo } from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  Category,
  LayoutTopBarProblemUnitViewProgressQuery,
} from "./__generated__/LayoutTopBarProblemUnitViewProgressQuery.graphql";
import { Skeleton, Stack, Typography } from "@mui/material";
import Suspensable from "../../common/Suspensable";
import useUser from "../../../user/useUser";
import LayoutContext from "../LayoutContext";
import { LayoutTopBarProblemUnitViewProgressRefetchQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql";
import { LayoutTopBarProblemUnitViewProgress_content$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_content.graphql";

const LayoutTopBarProblemUnitViewProgressContent: FC<Props> = ({
  category,
}): ReactElement => {
  const { shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate } =
    useContext(LayoutContext);
  const { user } = useUser();
  const dataFragment =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressQuery(
          $userID: ID
          $category: Category!
        ) {
          ...LayoutTopBarProblemUnitViewProgress_content
            @arguments(userID: $userID, category: $category)
        }
      `,
      { userID: user?.id, category },
    );

  const [data, refetch] = useRefetchableFragment<
    LayoutTopBarProblemUnitViewProgressRefetchQuery,
    LayoutTopBarProblemUnitViewProgress_content$key
  >(
    graphql`
      fragment LayoutTopBarProblemUnitViewProgress_content on Query
      @refetchable(queryName: "LayoutTopBarProblemUnitViewProgressRefetchQuery")
      @argumentDefinitions(
        userID: { type: "ID" }
        category: { type: "Category!" }
      ) {
        fetch__user(id: $userID) {
          completed_problems_count(category: $category)
        }
        multifetch__problem(where: { category: { equals: $category } }) {
          count
        }
      }
    `,
    dataFragment,
  );

  useEffect(() => {
    if (shouldTopBarProgressUpdate) {
      refetch({ userID: user?.id, category }, { fetchPolicy: "network-only" });
      setShouldTopBarProgressUpdate(false);
    }
  }, [
    category,
    refetch,
    setShouldTopBarProgressUpdate,
    shouldTopBarProgressUpdate,
    user?.id,
  ]);

  const {
    fetch__user,
    multifetch__problem: { count: totalProblemCount },
  } = data;

  const completedProblemCount = useMemo(
    () => fetch__user?.completed_problems_count ?? 0,
    [fetch__user?.completed_problems_count],
  );

  return (
    <Stack alignItems="center">
      <Stack direction="row" gap={1}>
        <Typography fontWeight="bold">{completedProblemCount}</Typography>
        <Typography color="textDisabled">/</Typography>
        <Typography color="textDisabled">{totalProblemCount}</Typography>
      </Stack>
      <Typography variant="subtitle2" color="textDisabled">
        Total
      </Typography>
    </Stack>
  );
};

interface Props {
  category: Category;
}

const LayoutTopBarProblemUnitViewProgress: FC<Props> = (
  props,
): ReactElement => {
  return (
    <Suspensable
      fallback={
        <Stack alignItems="center">
          <Stack direction="row" gap={1}>
            <Skeleton variant="text" width={40} />
            <Typography color="textDisabled">/</Typography>
            <Skeleton variant="text" width={40} />
          </Stack>
          <Typography variant="subtitle2" color="textDisabled">
            Total
          </Typography>
        </Stack>
      }
    >
      <LayoutTopBarProblemUnitViewProgressContent {...props} />
    </Suspensable>
  );
};

export default LayoutTopBarProblemUnitViewProgress;
