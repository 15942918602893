/* eslint-disable relay/unused-fields */
"use strict";

import { graphql } from "babel-plugin-relay/macro";
import {
  fetchQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay/hooks";
import {
  useUserQuery,
  useUserQuery$data,
} from "./__generated__/useUserQuery.graphql";
import { useCallback, useContext, useMemo } from "react";
import UserContext from "./UserContext";
import {
  useUserMutation,
  useUserMutation$variables,
} from "./__generated__/useUserMutation.graphql";
import { useUserUpdateResumeMutation } from "./__generated__/useUserUpdateResumeMutation.graphql";

export type User = NonNullable<useUserQuery$data["fetch__user"]>;

export default function useUser(): {
  user: User | null;
  isAdmin: boolean;
  fetchUser: (id: string | null) => void;
  updateUser: [
    (
      variables: useUserMutation$variables,
      avatarFile?: File,
      onCompleted?: () => void,
    ) => void,
    boolean,
  ];
  updateUserResume: [
    (resumeFile: File, onCompleted?: () => void) => void,
    boolean,
  ];
} {
  const { user, setUser } = useContext(UserContext);
  const environment = useRelayEnvironment();
  const [commit, isInFlight] = useMutation<useUserMutation>(graphql`
    mutation useUserMutation($name: String, $displayId: String, $file: Upload) {
      update__user(name: $name, display_id: $displayId, avatar_file: $file) {
        id
        name
        display_id
        picture_url
        resume_url
        role
      }
    }
  `);
  const [commitUpdateResume, isUpdateResumeInFlight] =
    useMutation<useUserUpdateResumeMutation>(graphql`
      mutation useUserUpdateResumeMutation($file: Upload) {
        update__user_resume(resume_file: $file) {
          id
          name
          display_id
          picture_url
          resume_url
          role
        }
      }
    `);

  const fetchUser = useCallback(
    (id: string | null) =>
      fetchQuery<useUserQuery>(
        environment,
        graphql`
          query useUserQuery($id: ID) {
            fetch__user(id: $id) {
              id
              name
              display_id
              picture_url
              resume_url
              role
            }
          }
        `,
        { id },
      ).subscribe({
        next: ({ fetch__user }) => setUser(fetch__user ?? null),
      }).unsubscribe,
    [environment, setUser],
  );

  const updateUser = useCallback(
    (
      variables: useUserMutation$variables,
      avatarFile?: File,
      onCompleted?: () => void,
    ) =>
      commit({
        variables,
        uploadables: avatarFile == null ? undefined : { file: avatarFile },
        onCompleted: ({ update__user }) => {
          setUser(update__user ?? null);
          onCompleted?.();
        },
      }),
    [commit, setUser],
  );

  const updateUserResume = useCallback(
    (resumeFile: File, onCompleted?: () => void) =>
      commitUpdateResume({
        variables: {},
        uploadables: { file: resumeFile },
        onCompleted: ({ update__user_resume }) => {
          setUser(update__user_resume ?? null);
          onCompleted?.();
        },
      }),
    [commitUpdateResume, setUser],
  );

  const isAdmin = useMemo(() => user?.role === "EDITOR", [user?.role]);

  return {
    user,
    isAdmin,
    fetchUser,
    updateUser: [updateUser, isInFlight],
    updateUserResume: [updateUserResume, isUpdateResumeInFlight],
  };
}
