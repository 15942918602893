/**
 * @generated SignedSource<<8b2469934c149f0aa1955a49ebaabee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Category = "INVESTMENT_BANKING" | "TECH";
export type LayoutTopBarProblemUnitViewProgressQuery$variables = {
  category: Category;
  userID?: string | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};
export type LayoutTopBarProblemUnitViewProgressQuery = {
  response: LayoutTopBarProblemUnitViewProgressQuery$data;
  variables: LayoutTopBarProblemUnitViewProgressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "userID",
            "variableName": "userID"
          }
        ],
        "kind": "FragmentSpread",
        "name": "LayoutTopBarProblemUnitViewProgress_content"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v2/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "completed_problems_count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equals",
                    "variableName": "category"
                  }
                ],
                "kind": "ObjectValue",
                "name": "category"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "ProblemConnection",
        "kind": "LinkedField",
        "name": "multifetch__problem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adaaffcd1670ea9e78301062b7a89495",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressQuery",
    "operationKind": "query",
    "text": "query LayoutTopBarProblemUnitViewProgressQuery(\n  $userID: ID\n  $category: Category!\n) {\n  ...LayoutTopBarProblemUnitViewProgress_content_9dFED\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_content_9dFED on Query {\n  fetch__user(id: $userID) {\n    completed_problems_count(category: $category)\n    id\n  }\n  multifetch__problem(where: {category: {equals: $category}}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "00625dddc3799a3ea836cc0a569560f0";

export default node;
