/**
 * @generated SignedSource<<7b7b033da59268894d467e132a753333>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Category = "INVESTMENT_BANKING" | "TECH";
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$variables = {
  category: Category;
  userID?: string | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery = {
  response: LayoutTopBarProblemUnitViewProgressRefetchQuery$data;
  variables: LayoutTopBarProblemUnitViewProgressRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "userID",
            "variableName": "userID"
          }
        ],
        "kind": "FragmentSpread",
        "name": "LayoutTopBarProblemUnitViewProgress_content"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "completed_problems_count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equals",
                    "variableName": "category"
                  }
                ],
                "kind": "ObjectValue",
                "name": "category"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "ProblemConnection",
        "kind": "LinkedField",
        "name": "multifetch__problem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53a4437daa95a0be9fc6ad32cf156ec1",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "operationKind": "query",
    "text": "query LayoutTopBarProblemUnitViewProgressRefetchQuery(\n  $category: Category!\n  $userID: ID\n) {\n  ...LayoutTopBarProblemUnitViewProgress_content_9dFED\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_content_9dFED on Query {\n  fetch__user(id: $userID) {\n    completed_problems_count(category: $category)\n    id\n  }\n  multifetch__problem(where: {category: {equals: $category}}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "b45d2fd38eddf564e1c3a873bfc4b9c3";

export default node;
