/**
 * @generated SignedSource<<93ab1221eb6347c75b40e836687ef34b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutTopBarProblemUnitViewProgress_content$data = {
  readonly fetch__user: {
    readonly completed_problems_count: number;
  } | null | undefined;
  readonly multifetch__problem: {
    readonly count: number;
  };
  readonly " $fragmentType": "LayoutTopBarProblemUnitViewProgress_content";
};
export type LayoutTopBarProblemUnitViewProgress_content$key = {
  readonly " $data"?: LayoutTopBarProblemUnitViewProgress_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userID"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql')
    }
  },
  "name": "LayoutTopBarProblemUnitViewProgress_content",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userID"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "fetch__user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "category",
              "variableName": "category"
            }
          ],
          "kind": "ScalarField",
          "name": "completed_problems_count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "equals",
                  "variableName": "category"
                }
              ],
              "kind": "ObjectValue",
              "name": "category"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ProblemConnection",
      "kind": "LinkedField",
      "name": "multifetch__problem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b45d2fd38eddf564e1c3a873bfc4b9c3";

export default node;
